import { Card } from '@/components'
import { Button, Code, Text, Textarea } from '@/components/catalyst'

const PromptCard = ({
  prompt,
  input,
  isFetching,
  setInput,
  clearThread,
  getAssistantOutput
}: {
  prompt: string
  input: string
  isFetching: boolean
  setInput: (input: string) => void
  clearThread: () => void
  getAssistantOutput: (input: string) => void
}) => {
  return (
    <Card
      title="Assistant"
      footer={
        <div className="flex justify-between space-x-4">
          <Button className="cursor-pointer" outline disabled={isFetching} onClick={clearThread}>
            Start Over
          </Button>
          <Button
            className="cursor-pointer"
            outline
            disabled={isFetching || input === ''}
            onClick={() => getAssistantOutput(input)}
          >
            Next
          </Button>
        </div>
      }
      loading={isFetching}
    >
      <Text>
        <Code>{prompt}</Code>
      </Text>
      <Textarea
        className="mt-4"
        name="assistant-input"
        placeholder="Enter message..."
        resizable={false}
        rows={4}
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
    </Card>
  )
}

export default PromptCard
