import Link from 'next/link'

import { Subheading, Text } from '@/components/catalyst'

import { SparklesIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/outline'

const DashboardCard = ({
  subheading,
  text,
  primaryHref,
  secondaryHref,
  Icon
}: {
  subheading: string
  text: string
  primaryHref: string
  secondaryHref: string
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
}) => {
  return (
    <div className="flex flex-col justify-between border rounded-lg bg-smet-secondary border-smet-teal-dark">
      <div className="flex flex-col items-center justify-center text-center space-y-2 max-w-36 m-auto py-8">
        <Icon className="h-8 w-8 text-smet-yellow-light-2" />
        <Subheading className="!text-white">{subheading}</Subheading>
        <Text className="!text-white !leading-tight">{text}</Text>
      </div>
      <div>
        <Link href={primaryHref}>
          <div className="p-4 flex items-center justify-center w-full text-smet-purple-dark bg-smet-purple-light-5">
            Start with Assistant
            <SparklesIcon className="text-smet-purple-dark ml-2 h-5 w-5" />
          </div>
        </Link>
        <Link href={secondaryHref}>
          <div className="p-4 flex items-center justify-center w-full text-smet-blue-dark bg-smet-blue-light-5 rounded-b-lg">
            Self-guided
            <WrenchScrewdriverIcon className="text-smet-blue-dark ml-2 h-5 w-5" />
          </div>
        </Link>
      </div>
    </div>
  )
}

export default DashboardCard
