import Image from 'next/image'

import { ChevronUpIcon, ArrowRightStartOnRectangleIcon, UserIcon } from '@heroicons/react/16/solid'

import Logo from '@assets/images/logos/be-logo.svg'
import { Dot } from '@/components'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeading,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarLayout,
  SidebarSection,
  Navbar,
  NavbarSpacer,
  NavbarSection,
  NavbarItem
} from '@/components/catalyst'

interface NavItem {
  href?: string
  label: string
  icon?: React.ReactNode
  notifications?: boolean
  onClick?: () => void
}

const SidebarNav = ({
  userName = '',
  userEmail = '',
  currentPath,
  sidebarItems,
  additionalItems,
  dropdownItems,
  notifications = false,
  logout,
  children
}: {
  userName?: string
  userEmail?: string
  currentPath: string
  sidebarItems: NavItem[]
  additionalItems?: { heading: string; items: NavItem[] }
  dropdownItems?: NavItem[]
  notifications?: boolean
  logout: () => void
  children: React.ReactNode
}) => {
  const dropdownMenu = (
    <DropdownMenu className="min-w-64" anchor="top start">
      {dropdownItems?.map((item) => {
        return (
          <DropdownItem className="cursor-pointer" key={item.href} href={item.href}>
            {item.icon}
            <DropdownLabel>{item.label}</DropdownLabel>
          </DropdownItem>
        )
      })}
      {dropdownItems && <DropdownDivider />}
      <DropdownItem className="cursor-pointer" onClick={logout}>
        <ArrowRightStartOnRectangleIcon />
        <DropdownLabel>Log out</DropdownLabel>
      </DropdownItem>
    </DropdownMenu>
  )

  const sidebar = (
    <Sidebar>
      <SidebarHeader className="flex items-center justify-center">
        <Image src={Logo} height={66} alt="BDecisive Logo" priority />
      </SidebarHeader>
      <SidebarBody>
        <SidebarSection>
          {sidebarItems.map((item) => {
            const current = item.href ? currentPath === item.href || currentPath.startsWith(item.href) : false

            return item.notifications && notifications ? (
              <SidebarItem key={item.href} href={item.href} current={current}>
                {item.icon}
                <SidebarLabel>
                  <div className="flex items-center">
                    {item.label}
                    <span className="ml-2">
                      <Dot />
                    </span>
                  </div>
                </SidebarLabel>
              </SidebarItem>
            ) : item.href ? (
              <SidebarItem key={item.href} href={item.href} current={current}>
                {item.icon}
                <SidebarLabel>{item.label}</SidebarLabel>
              </SidebarItem>
            ) : (
              <SidebarItem className="!cursor-pointer" key={item.href} current={current} onClick={item.onClick}>
                {item.icon}
                <SidebarLabel>{item.label}</SidebarLabel>
              </SidebarItem>
            )
          })}
        </SidebarSection>
        {additionalItems && (
          <SidebarSection>
            <SidebarHeading>{additionalItems.heading}</SidebarHeading>
            {additionalItems.items.map((item) => {
              return (
                <SidebarItem key={item.href} href={item.href}>
                  {item.label}
                </SidebarItem>
              )
            })}
          </SidebarSection>
        )}
      </SidebarBody>
      <SidebarFooter className="max-lg:hidden">
        <Dropdown>
          <DropdownButton as={SidebarItem}>
            <span className="flex min-w-0 items-center gap-3">
              <span className="min-w-0">
                <span className="block truncate text-sm/5 font-medium">{userName}</span>
                <span className="block truncate text-xs/5 font-normal">{userEmail}</span>
              </span>
            </span>
            <ChevronUpIcon className="!fill-black" />
          </DropdownButton>
          {dropdownMenu}
        </Dropdown>
      </SidebarFooter>
    </Sidebar>
  )

  const navbar = (
    <Navbar>
      <NavbarSpacer />
      <NavbarSection>
        <Dropdown>
          <DropdownButton as={NavbarItem}>
            <UserIcon />
          </DropdownButton>
          {dropdownMenu}
        </Dropdown>
      </NavbarSection>
    </Navbar>
  )

  return (
    <SidebarLayout navbar={navbar} sidebar={sidebar}>
      {children}
    </SidebarLayout>
  )
}

export default SidebarNav
