import numbro from 'numbro'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../catalyst'

const AiAssistedResourcePlanTable = ({
  resourcePlan,
  totals
}: {
  resourcePlan: Resource[]
  totals?: ResourcePlanTotals
}) => {
  return (
    <Table grid dense>
      <TableHead>
        <TableRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Role</TableHeader>
          <TableHeader>Rate</TableHeader>
          <TableHeader>Hours</TableHeader>
          <TableHeader>Employee Cost</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {resourcePlan.map((resource: Resource, index: number) => (
          <TableRow key={index}>
            <TableCell>{resource.name || '-'}</TableCell>
            <TableCell>{resource.role || '-'}</TableCell>
            <TableCell>{`$${resource.billRate} / hr` || '-'}</TableCell>
            <TableCell>{resource.billableHours || '-'}</TableCell>
            <TableCell>{numbro(resource.salary).formatCurrency({ average: true, mantissa: 1 })}</TableCell>
          </TableRow>
        ))}
        {totals ? (
          <TableRow className="bg-gray-200 font-semibold">
            <TableCell>-</TableCell>
            <TableCell>{totals.resources || '-'}</TableCell>
            <TableCell>{`$${totals.rate} / hr` || '-'}</TableCell>
            <TableCell>{totals.hours || '-'}</TableCell>
            <TableCell>{numbro(totals.salary).formatCurrency({ average: true, mantissa: 1 })}</TableCell>
          </TableRow>
        ) : null}
      </TableBody>
    </Table>
  )
}

export default AiAssistedResourcePlanTable
