export const DEFAULT_PROPOSED_SALARY = 110240
export const DEFAULT_WORKING_HOURS = 2080
export const DEFAULT_WRAP_RATE = 1.5
export const DEFAULT_LEAVE_ADJUSTED_HOURS = 1880
export const DEFAULT_BILLABLE_HOURS = 1920
export const DEFAULT_BILL_RATE = 79.5
export const DEFAULT_LOWER_SALARY_BOUND = 0.8
export const DEFAULT_HIGHER_SALARY_BOUND = 1.2
export const DEFAULT_SALARY_RANGE_STEP = 0.05

export const PROFIT_RED_COLOR = 'rgba(239, 68, 68)'
export const PROFIT_GREEN_COLOR = 'rgba(52, 211, 153)'
export const PROFIT_GRAY_COLOR = 'rgba(156, 163, 175)'
