import { useEffect, useState } from 'react'

import { AiAssistedResourcePlanCard } from '@/components'

import DecisionTreeCard from './DecisionTreeCard'
import InsightsCard from './InsightsCard'
import PromptCard from './PromptCard'

const THREAD_KEY = 'assistant_thread_id'
const PROJECT_KEY = 'assistant_project_id'

const HiringAssistant = ({ initialQuery }: { initialQuery: string | null }) => {
  const [assistantInput, setAssistantInput] = useState<string>('')
  const [assistantOutput, setAssistantOutput] = useState<AssistantOutput>({})
  const [isFetching, setIsFetching] = useState<boolean>(true)

  const getMessages = async (threadId: string) => {
    if (threadId) {
      try {
        const res = await fetch('api/assistant/threads/messages', {
          method: 'POST',
          body: JSON.stringify({
            threadId
          })
        })

        if (res.ok) {
          res.json().then((data) => {
            setAssistantOutput({
              threadId,
              response: JSON.parse(data.messages[data.messages.length - 1].text)
            })
          })
        } else {
          const error = await res.json().then((json) => json.error)
          throw new Error(error)
        }
      } catch (error) {
        alert(error)
        setAssistantOutput({})
      } finally {
        setIsFetching(false)
      }
    }
  }

  const getAssistantOutput = async (query: string | null, threadId: string) => {
    setIsFetching(true)

    try {
      const res = await fetch('api/assistant/hiring', {
        method: 'POST',
        body: JSON.stringify({
          query,
          threadId
        })
      })

      if (res.ok) {
        res.json().then((data) => {
          setAssistantOutput({
            threadId: data.threadId,
            response: JSON.parse(data.text)
          })
          setAssistantInput('')
          sessionStorage.setItem(THREAD_KEY, data.threadId)
        })
      } else {
        const error = await res.json().then((json) => json.error)
        throw new Error(error)
      }
    } catch (error) {
      alert(error)
      setAssistantOutput({})
    } finally {
      setIsFetching(false)
    }
  }

  const handleClearThread = () => {
    sessionStorage.removeItem(PROJECT_KEY)
    getAssistantOutput(initialQuery, '')
  }

  useEffect(() => {
    const threadId = sessionStorage.getItem(THREAD_KEY)

    if (threadId) {
      getMessages(threadId)
    } else if (initialQuery) {
      getAssistantOutput(initialQuery, '')
    }
  }, [initialQuery])

  const prompt: string = assistantOutput?.response?.prompt || ''
  const threadId: string = assistantOutput?.threadId || ''
  const employees: Resource[] = assistantOutput?.response?.employees || []
  const insights: string = assistantOutput?.response?.insights || ''
  const totals: ResourcePlanTotals | undefined = assistantOutput?.response?.resourcePlanTotals
  const decisions: Decision[] = assistantOutput?.response?.decisions || []

  return (
    <div className="space-y-8">
      <div className="gap-4 space-y-8 md:flex md:space-y-0">
        <div className="md:w-1/3">
          <PromptCard
            prompt={prompt}
            input={assistantInput}
            isFetching={isFetching}
            setInput={setAssistantInput}
            clearThread={handleClearThread}
            getAssistantOutput={(input: string) => getAssistantOutput(input, threadId)}
          />
        </div>
        <div className="md:w-1/3">
          <InsightsCard insights={insights} loading={isFetching} />
        </div>
        <div className="md:w-1/3">
          <DecisionTreeCard decisions={decisions} />
        </div>
      </div>
      <div>
        <AiAssistedResourcePlanCard
          projectKey={PROJECT_KEY}
          resourcePlan={employees}
          totals={totals}
          projectName={assistantOutput?.response?.projectName}
        />
      </div>
    </div>
  )
}

export default HiringAssistant
