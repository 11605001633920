import { Card, Dialog, ResourcePlanTable, ZodForm } from '@/components'
import { Button } from '@/components/catalyst'
import { ResourcePlanFeedbackSchema } from '@/utils/schemas'

const saveResourcePlanFormFields = [
  {
    name: 'strategicGoalsAlignment',
    label: 'Does the resource plan align with company strategic goals?',
    config: {
      field: 'input' as const,
      type: 'text',
      readOnly: true
    }
  },
  {
    name: 'feedback',
    label: 'Provide additional feedback on the rating above',
    config: {
      field: 'textarea' as const,
      rows: 4,
      resizable: false,
      readOnly: true
    }
  }
]

const ViewFeedbackModal = ({ resourcePlan, handleClose }: { resourcePlan: ResourcePlan; handleClose: () => void }) => {
  return (
    <Dialog
      open
      size="5xl"
      title="Feedback for Resource Plan"
      actions={
        <Button className="cursor-pointer" outline onClick={handleClose}>
          Close
        </Button>
      }
      onClose={handleClose}
    >
      <div className="space-y-8 lg:flex lg:space-x-16 lg:space-y-0">
        <div className="lg:w-1/2 space-y-8">
          <ZodForm
            id="view-resource-plan-form"
            fields={saveResourcePlanFormFields}
            schema={ResourcePlanFeedbackSchema}
            defaultValues={JSON.parse(resourcePlan.feedback)}
          />
        </div>
        <div className="lg:w-1/2">
          <Card title={resourcePlan.name}>
            <ResourcePlanTable resourcePlan={resourcePlan} />
          </Card>
        </div>
      </div>
    </Dialog>
  )
}

export default ViewFeedbackModal
