'use client'

import { Dialog, ZodForm } from '@/components'
import { Button } from '@/components/catalyst'
import { ChangeTemporaryPasswordSchema, ChangeTemporaryPasswordType } from '@/utils/schemas'

const changeTemporaryPasswordFormFields = [
  {
    name: 'password',
    label: 'Password',
    config: {
      field: 'input' as const,
      type: 'password'
    }
  },
  {
    name: 'confirmPassword',
    label: 'Confirm Password',
    config: {
      field: 'input' as const,
      type: 'password'
    }
  }
]

const ChangeTemporaryPasswordModal = ({
  isOpen,
  handleChange,
  handleClose
}: {
  isOpen: boolean
  handleChange: (newPassword: string) => void
  handleClose: () => void
}) => {
  const changePassword = async (data: ChangeTemporaryPasswordType) => {
    handleChange(data.password)
  }

  return (
    <Dialog
      open={isOpen}
      title="Create Password"
      description="You must create a new password before logging in."
      actions={
        <>
          <Button className="cursor-pointer" plain onClick={handleClose}>
            Cancel
          </Button>
          <Button className="cursor-pointer" type="submit" form="change-temporary-password-form">
            Create
          </Button>
        </>
      }
      onClose={handleClose}
    >
      <ZodForm
        id="change-temporary-password-form"
        fields={changeTemporaryPasswordFormFields}
        schema={ChangeTemporaryPasswordSchema}
        onSubmit={changePassword}
      />
    </Dialog>
  )
}

export default ChangeTemporaryPasswordModal
