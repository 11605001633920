import { z } from 'zod'

export const ResourceSchema = z.object({
  name: z.string().min(1, 'Required'),
  role: z.string().min(1, 'Required'),
  billRate: z.number().min(0),
  billableHours: z.number().min(0),
  fullyBurdenedRate: z.number().min(0)
})

export const ResourcePlanSchema = z
  .object({
    resourcePlan: z.array(ResourceSchema),
    name: z.string().min(1, 'Required').max(50, 'Maximum 50 characters'),
    startDate: z.string().date(),
    endDate: z.string().date()
  })
  .refine((data) => new Date(data.startDate) < new Date(data.endDate), {
    path: ['endDate'],
    message: 'End date must be after start date'
  })

export type resourceType = z.infer<typeof ResourceSchema>
export type resourcePlanType = z.infer<typeof ResourcePlanSchema>
