import {
  DEFAULT_BILLABLE_HOURS,
  DEFAULT_LEAVE_ADJUSTED_HOURS,
  DEFAULT_WORKING_HOURS,
  DEFAULT_WRAP_RATE,
  DEFAULT_LOWER_SALARY_BOUND,
  DEFAULT_HIGHER_SALARY_BOUND,
  DEFAULT_SALARY_RANGE_STEP
} from './constants'

const getHourlyRate = (salary: number, workingHours: number = DEFAULT_WORKING_HOURS) => {
  return salary / workingHours
}

export const getRevenue = (billableRate: number, workingHours = DEFAULT_BILLABLE_HOURS) => {
  return billableRate * workingHours
}

const getFullyBurdenedRate = (hourlyRate: number, wrapRate: number = DEFAULT_WRAP_RATE) => {
  return hourlyRate * wrapRate
}

export const getFullyBurdenedCost = (
  fullyBurdenedRate: number,
  leaveAdjustedHours: number = DEFAULT_LEAVE_ADJUSTED_HOURS
) => {
  return fullyBurdenedRate * leaveAdjustedHours
}

export const getHourlyProfit = (billableRate: number, fullyBurdenedRate: number) => {
  return billableRate - fullyBurdenedRate
}

const getPercentProfit = (billableRate: number, fullyBurdenedRate: number) => {
  return (billableRate - fullyBurdenedRate) / fullyBurdenedRate
}

const getProjectedProfit = (
  billableRate: number,
  fullyBurdenedCost: number,
  leaveAdjustedHours: number = DEFAULT_LEAVE_ADJUSTED_HOURS
) => {
  return billableRate * leaveAdjustedHours - fullyBurdenedCost
}

export const getMaxProjectedProfit = (
  billableRate: number,
  fullyBurdenedCost: number,
  billableHours: number = DEFAULT_BILLABLE_HOURS
) => {
  return billableRate * billableHours - fullyBurdenedCost
}

const getBreakevenPoint = (
  billableRate: number,
  wrapRate: number = DEFAULT_WRAP_RATE,
  workingHours: number = DEFAULT_WORKING_HOURS
) => {
  return (billableRate / wrapRate) * workingHours
}

export const getCalculations = (
  proposedSalary: number,
  billableRate: number,
  wrapRate: number = DEFAULT_WRAP_RATE,
  leaveAdjustedHours: number = DEFAULT_LEAVE_ADJUSTED_HOURS
) => {
  const hourlyRate = getHourlyRate(proposedSalary)
  const fullyBurdenedRate = getFullyBurdenedRate(hourlyRate, wrapRate)
  const fullyBurdenedCost = getFullyBurdenedCost(fullyBurdenedRate, leaveAdjustedHours)

  return {
    salary: proposedSalary,
    hourlyRate,
    fullyBurdenedRate,
    fullyBurdenedCost,
    percentProfit: getPercentProfit(billableRate, fullyBurdenedRate),
    projectedProfit: getProjectedProfit(billableRate, fullyBurdenedCost, leaveAdjustedHours),
    maxProjectedProfit: getMaxProjectedProfit(billableRate, fullyBurdenedCost)
  }
}

export const getSalaryRanges = (
  proposedSalary: number,
  billableRate: number,
  wrapRate: number = DEFAULT_WRAP_RATE,
  leaveAdjustedHours: number = DEFAULT_LEAVE_ADJUSTED_HOURS,
  lowerSalaryBound: number = DEFAULT_LOWER_SALARY_BOUND,
  higherSalaryBound: number = DEFAULT_HIGHER_SALARY_BOUND,
  rangeStep: number = DEFAULT_SALARY_RANGE_STEP
) => {
  const lowerSalary = proposedSalary * lowerSalaryBound
  const higherSalary = proposedSalary * higherSalaryBound
  const increment = proposedSalary * rangeStep

  if (proposedSalary) {
    const ranges = [
      getCalculations(getBreakevenPoint(billableRate, wrapRate), billableRate, wrapRate, leaveAdjustedHours)
    ]

    for (let salary = lowerSalary; salary <= higherSalary; salary = salary + increment) {
      ranges.push(getCalculations(salary, billableRate, wrapRate, leaveAdjustedHours))
    }

    return ranges
  }

  return []
}
