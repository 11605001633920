export enum PersonnelType {
  Individual = 'individual',
  Group = 'group'
}

export enum LaborType {
  Regular = 'regular',
  Direct = 'direct'
}

export enum RoleType {
  Staff = 'staff',
  Contract = 'contract'
}

export enum SalaryType {
  Fixed = 'fixed',
  Percentage = 'percentage'
}

export enum SalaryCadence {
  Monthly = 'monthly',
  Annualy = 'annually'
}

export const enumToSelectOptions = (e: Record<string, string>) => {
  return Object.values(e).map((value) => ({
    name: value.charAt(0).toUpperCase() + value.slice(1),
    value
  }))
}
