import {
  getFullyBurdenedCost,
  getHourlyProfit,
  getMaxProjectedProfit,
  getRevenue
} from '@/components/salary-calculator/lib/calculations'
import { formatCurrency } from '@/components/salary-calculator/lib/numbro'

import { resourceType } from './schemas'

const formatCurrencyPerHour = (num: number) => {
  return `${formatCurrency(num)} / hr`
}

export const TABLE_CONFIG = [
  {
    label: 'Name',
    key: 'name',
    field: {
      type: 'combobox'
    }
  },
  {
    label: 'Role',
    key: 'role',
    field: {
      type: 'text'
    }
  },
  {
    label: 'Hours',
    key: 'billableHours',
    field: {
      type: 'number'
    }
  },
  {
    label: 'Bill Rate',
    key: 'billRate',
    aggregateKey: 'totalBillRate',
    field: {
      type: 'number'
    },
    format: (billRate: number) => {
      return formatCurrencyPerHour(billRate)
    }
  },
  {
    label: 'Fully Burdened Rate',
    key: 'fullyBurdenedRate',
    aggregateKey: 'totalFullyBurdenedRate',
    field: {
      type: 'number'
    },
    format: (fullyBurdenedRate: number) => {
      return formatCurrencyPerHour(fullyBurdenedRate)
    }
  },
  {
    label: 'Revenue',
    aggregateKey: 'totalRevenue',
    compute: (resource: resourceType) => {
      return getRevenue(resource.billRate, resource.billableHours)
    },
    format: (revenue: number) => {
      return formatCurrency(revenue)
    }
  },
  {
    label: 'Profit/Loss Rate',
    aggregateKey: 'totalHourlyProfit',
    compute: (resource: resourceType) => {
      return getHourlyProfit(resource.billRate, resource.fullyBurdenedRate)
    },
    format: (hourlyProfit: number) => {
      return formatCurrencyPerHour(hourlyProfit)
    }
  },
  {
    label: 'Profit/Loss Total',
    aggregateKey: 'totalProfit',
    compute: (resource: resourceType) => {
      const { billableHours, billRate, fullyBurdenedRate } = resource

      const fullyBurdenedCost = getFullyBurdenedCost(fullyBurdenedRate, billableHours)
      const profit = getMaxProjectedProfit(billRate, fullyBurdenedCost, billableHours)

      return profit
    },
    format: (profit: number) => {
      return formatCurrency(profit)
    }
  }
]
