import { Card, Dialog, ResourcePlanTable, ZodForm } from '@/components'
import { Button } from '@/components/catalyst'
import { ResourcePlanFeedbackSchema, ResourcePlanFeedbackType } from '@/utils/schemas'

const resourcePlanFeedbackFormFields = [
  {
    name: 'strategicGoalsAlignment',
    label: 'Does the resource plan align with company strategic goals?',
    config: {
      field: 'select' as const,
      options: [
        {
          name: '',
          value: ''
        },
        {
          name: 'Completely Agree',
          value: 'completely-agree'
        },
        {
          name: 'Somewhat Agree',
          value: 'somewhat-agree'
        },
        {
          name: 'Neutral',
          value: 'neutral'
        },
        {
          name: 'Somewhat Disagree',
          value: 'somewhat-disagree'
        },
        {
          name: 'Completely Disagree',
          value: 'completely-disagree'
        }
      ]
    }
  },
  {
    name: 'feedback',
    label: 'Provide additional feedback on the rating above',
    config: {
      field: 'textarea' as const,
      rows: 4,
      resizable: false
    }
  }
]

const ResourcePlanFeedbackModal = ({
  resourcePlan,
  handleClose
}: {
  resourcePlan: ResourcePlan
  handleClose: (success: boolean) => void
}) => {
  const submitFeedback = async (data: ResourcePlanFeedbackType) => {
    try {
      const res = await fetch(`/api/requested-feedback/${resourcePlan.project_id}/${resourcePlan.id}/submit`, {
        method: 'PUT',
        body: JSON.stringify({
          form: data
        })
      })

      if (res.ok) {
        handleClose(true)
      } else {
        const error = await res.json().then((json) => json.error)
        throw new Error(error)
      }
    } catch (error) {
      alert(error)
    }
  }

  return (
    <Dialog
      open
      size="5xl"
      title="Feedback for Resource Plan"
      description="Submit feedback to the business on the resource plan shown below."
      actions={
        <>
          <Button className="cursor-pointer" plain onClick={() => handleClose(false)}>
            Cancel
          </Button>
          <Button className="cursor-pointer" type="submit" form="resource-plan-feedback-form">
            Submit Feedback
          </Button>
        </>
      }
      onClose={() => handleClose(false)}
    >
      <div className="space-y-8 lg:flex lg:space-x-16 lg:space-y-0">
        <div className="lg:w-1/2 space-y-8">
          <ZodForm
            id="resource-plan-feedback-form"
            fields={resourcePlanFeedbackFormFields}
            schema={ResourcePlanFeedbackSchema}
            onSubmit={submitFeedback}
          />
        </div>
        <div className="lg:w-1/2">
          <Card title={resourcePlan.name}>
            <ResourcePlanTable resourcePlan={resourcePlan} />
          </Card>
        </div>
      </div>
    </Dialog>
  )
}

export default ResourcePlanFeedbackModal
