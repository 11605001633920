import { ChangeEvent, FocusEvent } from 'react'
import Slider from 'rc-slider'
import {
  FormControl,
  FormLabel,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

import { fields } from './lib/form'

const getLabel = (label: string, tooltipContent: string) => {
  return (
    <div className="flex items-center">
      {label}{' '}
      <span className="flex ml-1">
        <Popover trigger="hover" placement="right">
          <PopoverTrigger>
            <InformationCircleIcon className="w-5 h-5" />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody>{tooltipContent}</PopoverBody>
          </PopoverContent>
        </Popover>
      </span>
    </div>
  )
}

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSliderBlur
}: {
  values: CalculatorFormValues
  errors: CalculatorFormValues
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleBlur: (e: FocusEvent<HTMLInputElement>) => void
  handleSliderBlur: (e: FocusEvent<HTMLInputElement>) => void
}) => {
  return (
    <form>
      {fields.map((field) => {
        const {
          name,
          label,
          constraints: { min, max, step }
        } = field

        const value = values[name as keyof typeof values]

        return (
          <FormControl key={name}>
            <Stack className="mt-8">
              <FormLabel>{label.tooltip ? getLabel(label.name, label.tooltip) : label.name}</FormLabel>
              <Input id={name} name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
              <Slider
                min={min}
                max={max}
                step={step}
                value={Number(value) || 0}
                onChange={(val) => handleChange({ target: { name, value: String(val) } } as any)}
                onChangeComplete={(val) => handleSliderBlur({ target: { name, value: String(val) } } as any)}
                styles={{
                  track: {
                    backgroundColor: '#0054e8'
                  },
                  handle: {
                    backgroundColor: '#0054e8',
                    borderColor: '#0054e8',
                    boxShadow: '0 0 0 5px #0054e8'
                  }
                }}
              />
              {errors[name as keyof typeof errors] && (
                <>
                  <Text fontSize="small" color="red">
                    {errors[name as keyof typeof errors]}
                  </Text>
                </>
              )}
            </Stack>
          </FormControl>
        )
      })}
    </form>
  )
}

export default Form
