import { ReactNode, useEffect, useState } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { signOut } from 'aws-amplify/auth'

import { ChatBubbleLeftEllipsisIcon, HomeIcon } from '@heroicons/react/16/solid'

import { ClipLoader, useNotifications, useUser } from '@/components'

import SidebarNav from './SidebarNav'

const items = [
  {
    label: 'Home',
    href: '/ba-dashboard',
    icon: <HomeIcon />
  },
  {
    label: 'Requested Feedback',
    href: '/requested-feedback',
    icon: <ChatBubbleLeftEllipsisIcon />,
    notifications: true
  }
]

const BusinessAdvisorNav = ({ children }: { children: ReactNode }) => {
  const [hasUnresolvedFeedback, setHasUnresolvedFeedback] = useState<boolean>(false)

  const { loading: notificationsLoading, notifications } = useNotifications()
  const { loading: userLoading, user, setUser } = useUser()
  const pathname = usePathname()
  const router = useRouter()

  const logout = async () => {
    try {
      await signOut()
      setUser(null)
      router.push('/auth/login')
    } catch (error) {
      alert(error)
    }
  }

  useEffect(() => {
    if (!userLoading && !notificationsLoading) {
      setHasUnresolvedFeedback(notifications)
    }
  }, [userLoading, notificationsLoading, notifications])

  if (userLoading || notificationsLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <ClipLoader />
      </div>
    )
  }

  return (
    <SidebarNav
      userName={user?.attributes.name}
      userEmail={user?.attributes.email}
      currentPath={pathname}
      sidebarItems={items}
      notifications={hasUnresolvedFeedback}
      logout={logout}
    >
      {children}
    </SidebarNav>
  )
}

export default BusinessAdvisorNav
