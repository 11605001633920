import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'

import { useUser } from './UserContextProvider'

interface PersonnelContextType {
  personnel: { name: string }[]
  loading: boolean
  setPersonnel: (personnel: { name: string }[]) => void
}

const PersonnelContext = createContext<PersonnelContextType | undefined>(undefined)

export const PersonnelProvider = ({ children }: { children: ReactNode }) => {
  const [personnel, setPersonnel] = useState<{ name: string }[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { user } = useUser()

  useEffect(() => {
    const fetchPersonnel = async () => {
      try {
        const res = await fetch('/api/personnel', {
          method: 'GET'
        })

        if (res.ok) {
          res.json().then((json) => {
            setPersonnel(json.data.personnel)
          })
        }
      } catch (error) {
        setPersonnel([])
      } finally {
        setLoading(false)
      }
    }

    fetchPersonnel()
  }, [user])

  return <PersonnelContext.Provider value={{ personnel, loading, setPersonnel }}>{children}</PersonnelContext.Provider>
}

export const usePersonnel = () => {
  const context = useContext(PersonnelContext)

  if (!context) {
    throw new Error('usePersonnel must be used within a PersonnelProvider')
  }

  return context
}
