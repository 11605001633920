import { FieldErrors, UseFieldArrayRemove, UseFormRegister } from 'react-hook-form'

import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/solid'

import { Combobox, usePersonnel } from '@/components'
import { Field, Input, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/catalyst'

import { resourcePlanType, resourceType } from './schemas'
import { TABLE_CONFIG } from './config'

const renderTableHead = (fields: { id: string }[], remove: UseFieldArrayRemove) => {
  const deleteColumnCells = fields.map((field, index) => {
    return fields.length > 1 ? (
      <TableHeader key={field.id}>
        <MinusCircleIcon
          key={field.id}
          className="text-smet-red cursor-pointer h-6 w-6 m-auto"
          onClick={() => remove(index)}
        />
      </TableHeader>
    ) : (
      <TableHeader key={field.id} />
    )
  })

  return (
    <TableHead>
      <TableRow>
        <TableHeader />
        {deleteColumnCells}
        <TableHeader />
      </TableRow>
    </TableHead>
  )
}

const renderTableBody = (
  values: resourceType[],
  totals: any,
  errors: FieldErrors<resourcePlanType>,
  personnel: { name: string }[],
  register: UseFormRegister<resourcePlanType>
) => {
  const body = TABLE_CONFIG.map((config) => {
    const { label, key, aggregateKey, field, compute, format } = config

    return (
      <TableRow key={label}>
        <TableCell className="font-bold">{label}</TableCell>
        {values.map((resource, index) => {
          if (aggregateKey) {
            totals[aggregateKey as keyof typeof totals] += compute
              ? compute(resource)
              : Number(resource[key as keyof typeof resource])
          }

          if (key) {
            let input

            if (field.type === 'text') {
              input = (
                <Input
                  type="text"
                  {...register(`resourcePlan.${index}.${key}` as any)}
                  invalid={!!errors?.resourcePlan?.[index]?.[key as keyof (typeof errors.resourcePlan)[typeof index]]}
                />
              )
            } else if (field.type === 'number') {
              input = (
                <Input
                  type="number"
                  {...register(`resourcePlan.${index}.${key}` as any, {
                    setValueAs: (value: string) => parseFloat(value)
                  })}
                  invalid={!!errors?.resourcePlan?.[index]?.[key as keyof (typeof errors.resourcePlan)[typeof index]]}
                />
              )
            } else {
              input = (
                <Combobox
                  {...register(`resourcePlan.${index}.${key}` as any)}
                  options={personnel}
                  value={{ name: resource[key as keyof typeof resource] as string }}
                  invalid={!!errors?.resourcePlan?.[index]?.[key as keyof (typeof errors.resourcePlan)[typeof index]]}
                />
              )
            }

            return (
              <TableCell key={`${label}-${index}`}>
                <Field>{input}</Field>
              </TableCell>
            )
          }

          return (
            <TableCell key={`resource-${index}`}>
              {format
                ? format(compute ? compute(resource) : Number(resource[key as keyof typeof resource]))
                : resource[key as keyof typeof resource]}
            </TableCell>
          )
        })}
        <TableCell className="font-bold">
          {aggregateKey ? format(totals[aggregateKey as keyof typeof totals]) : ''}
        </TableCell>
      </TableRow>
    )
  })

  return <TableBody>{body}</TableBody>
}

const CreateSelfGuidedResourcePlanTable = ({
  fields,
  values,
  errors,
  register,
  append,
  remove
}: {
  fields: { id: string }[]
  values: resourceType[]
  errors: FieldErrors<resourcePlanType>
  register: UseFormRegister<resourcePlanType>
  append: () => void
  remove: UseFieldArrayRemove
}) => {
  const totals = {
    totalBillRate: 0,
    totalFullyBurdenedRate: 0,
    totalRevenue: 0,
    totalHourlyProfit: 0,
    totalProfit: 0
  }

  const { personnel } = usePersonnel()

  return (
    <div className="flex flex-col">
      <Table grid dense>
        {renderTableHead(fields, remove)}
        {renderTableBody(values, totals, errors, personnel, register)}
      </Table>
      {fields.length < 5 && (
        <PlusCircleIcon className="text-smet-primary-blue h-10 w-10 mt-4 self-center cursor-pointer" onClick={append} />
      )}
    </div>
  )
}

export default CreateSelfGuidedResourcePlanTable
