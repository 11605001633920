import { useCallback, useRef, useState } from 'react'

import { CreateSelfGuidedResourcePlanCard, ViewSelfGuidedResourcePlanCard } from '@/components'
import { resourcePlanType } from '@/components/resource-plan/self/schemas'

import ChatCard from './ChatCard'
import { defaultResourcePlan, initialAiMessages } from './config'

interface Message {
  role: string
  text?: string
  html?: string
}

interface ChatRef {
  submitUserMessage: (message: { text: string }) => void
}

const SelfGuidedAssistant = () => {
  const [isEditingResourcePlan, setIsEditingResourcePlan] = useState<boolean>(true)
  const [resourcePlan, setResourcePlan] = useState<resourcePlanType>(defaultResourcePlan)
  const [projectId, setProjectId] = useState<string>('')
  const [showAiAssistant, setShowAiAssistant] = useState<boolean>(false)
  const [assistantChatHistory, setAssistantChatHistory] = useState<Message[]>([...initialAiMessages])

  const chatRef = useRef<ChatRef | null>(null)

  const handleChange = useCallback((resourcePlan: resourcePlanType) => {
    setResourcePlan(resourcePlan)
  }, [])

  const handleToggleDone = (resourcePlan: resourcePlanType) => {
    if (showAiAssistant) {
      setTimeout(() => {
        chatRef.current?.submitUserMessage({ text: '**Resource Plan Updated**' })
      }, 1000)
    }

    setResourcePlan(resourcePlan)
    setIsEditingResourcePlan(false)
  }

  const handleChatRender = (ref: ChatRef) => {
    chatRef.current = ref
  }

  const handleNewMessage = (newMessage: { isHistory: boolean; message: Message }) => {
    const { isHistory, message } = newMessage

    if (isHistory) {
      return
    }

    const messages = assistantChatHistory
    messages.push(message)
    setAssistantChatHistory(messages)
  }

  return (
    <div className="space-y-4 xl:space-y-0 xl:flex xl:space-x-4">
      {isEditingResourcePlan ? (
        <div className="xl:w-2/3">
          <CreateSelfGuidedResourcePlanCard
            resourcePlan={resourcePlan}
            handleDone={handleToggleDone}
            handleChange={handleChange}
          />
        </div>
      ) : (
        <div className="xl:w-2/3">
          <ViewSelfGuidedResourcePlanCard
            resourcePlan={resourcePlan}
            projectId={projectId}
            handleEdit={() => setIsEditingResourcePlan(true)}
            handleProjectCreation={(projectId: string) => setProjectId(projectId)}
            handleAiInsights={() => setShowAiAssistant(true)}
          />
        </div>
      )}
      {showAiAssistant && (
        <div className="xl:w-1/3">
          <ChatCard
            resourcePlan={resourcePlan}
            history={assistantChatHistory}
            onMessage={handleNewMessage}
            handleRender={handleChatRender}
          />
        </div>
      )}
    </div>
  )
}

export default SelfGuidedAssistant
