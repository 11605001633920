import { Card } from '@/components'
import { Text } from '@/components/catalyst'

const InsightsCard = ({ insights, loading }: { insights?: string; loading: boolean }) => {
  return (
    <Card title="Insights" loading={loading}>
      <Text>{insights || 'Insights will populate as you interact with the Assistant.'}</Text>
    </Card>
  )
}

export default InsightsCard
