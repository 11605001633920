import { forwardRef, useState } from 'react'

import {
  Combobox as HeadlessCombobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

interface CustomComboboxProps {
  options: { name: string }[]
  value: { name: string }
  invalid?: boolean
}

const Combobox = forwardRef<HTMLInputElement, CustomComboboxProps>(
  ({ options, value, invalid, ...registerProps }: any, ref) => {
    const [query, setQuery] = useState('')

    const filteredPeople =
      query === ''
        ? options
        : options.filter((option: any) => {
            return option.name.toLowerCase().includes(query.toLowerCase())
          })

    return (
      <HeadlessCombobox
        as="div"
        value={value}
        onChange={(value) => registerProps.onChange({ target: { name: registerProps.name, value: value?.name } })}
      >
        <div className="relative">
          <ComboboxInput
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm/6 after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent sm:after:focus-within:ring-2 sm:after:focus-within:ring-blue-500 data-[invalid]:ring-red-500 data-[invalid]:data-[hover]:ring-red-500 data-[invalid]:dark:ring-red-500 data-[invalid]:data-[hover]:dark:ring-red-500"
            {...registerProps}
            ref={ref}
            onChange={(event) => {
              setQuery(event.target.value)
              registerProps.onChange(event)
            }}
            onBlur={() => setQuery('')}
            displayValue={(person: any) => person?.name}
            data-invalid={invalid || undefined}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
          </ComboboxButton>

          <ComboboxOptions className="fixed z-10 mt-1 max-h-60 max-w-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople?.map((option: { name: string }, index: number) => (
              <ComboboxOption
                key={`${option}-${index}`}
                value={option}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-smet-primary-blue data-[focus]:text-white data-[focus]:outline-none"
              >
                <span className="block truncate group-data-[selected]:font-semibold">{option.name}</span>

                <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-smet-primary-blue group-data-[selected]:flex group-data-[focus]:text-white">
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </div>
      </HeadlessCombobox>
    )
  }
)

Combobox.displayName = 'Combobox'

export default Combobox
