import AiAssistedResourcePlanTable from './ai/AiAssistedResourcePlanTable'
import ViewSelfGuidedResourcePlanTable from './self/ViewSelfGuidedResourcePlanTable'

const ResourcePlanTable = ({ resourcePlan }: { resourcePlan: ResourcePlan }) => {
  const plan = JSON.parse(resourcePlan.resource_plan)

  if (resourcePlan.self_guided) {
    return <ViewSelfGuidedResourcePlanTable resourcePlan={plan} />
  }

  return <AiAssistedResourcePlanTable resourcePlan={plan} totals={JSON.parse(resourcePlan.totals)} />
}

export default ResourcePlanTable
