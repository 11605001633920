import { resourcePlanType } from '@/components/resource-plan/self/schemas'

const defaultQuestions = [
  'How do my bill rates and fully burdened rates compare to similar businesses in my industry and region?',
  'Which roles are generating the highest profit margins, and which are underperforming?',
  'What are typical profit margins for businesses of my size in this market?'
]

export const initialAiMessages = [
  {
    text: "Let's review your resource plan to ensure each role is cost-effective and aligned with your financial goals. Is there anything you would like me to analyze about your resource plan?",
    role: 'ai'
  },
  {
    html: `
      <div class="deep-chat-temporary-message">
        <button class="deep-chat-button deep-chat-suggestion-button" style="margin-top: 5px">${defaultQuestions[0]}</button>
        <button class="deep-chat-button deep-chat-suggestion-button" style="margin-top: 6px">${defaultQuestions[1]}</button>
        <button class="deep-chat-button deep-chat-suggestion-button" style="margin-top: 6px">${defaultQuestions[2]}</button>
      </div>`,
    role: 'ai'
  }
]

export const defaultResourcePlan: resourcePlanType = {
  resourcePlan: [
    {
      name: '',
      role: 'Engineer',
      billRate: 0,
      billableHours: 1920,
      fullyBurdenedRate: 0
    }
  ],
  name: 'Resource Plan',
  startDate: '',
  endDate: ''
}
