import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Text } from '@chakra-ui/react'

import { PROFIT_GRAY_COLOR, PROFIT_GREEN_COLOR, PROFIT_RED_COLOR } from './lib/constants'
import { formatCurrency } from './lib/numbro'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const ProfitChart = ({ proposedSalary, data }: { proposedSalary: PotentialSalary; data: PotentialSalary[] }) => {
  const labels = []
  const profits = []

  for (let i = 1; i < data.length; i++) {
    labels.push(formatCurrency(data[i].salary))
    profits.push(data[i].percentProfit * 100)
  }

  const profitChartData = {
    labels,
    datasets: [
      {
        label: 'Profit',
        data: profits,
        backgroundColor: profits.map((profit) =>
          profit === 0 ? PROFIT_GRAY_COLOR : profit < 0 ? PROFIT_RED_COLOR : PROFIT_GREEN_COLOR
        )
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        grid: {
          color: 'gray',
          drawTicks: false
        }
      }
    }
  }

  return (
    <div className="text-center">
      <Text as="strong" fontSize="large">
        Proposed Salary: {formatCurrency(proposedSalary.salary)}
      </Text>
      <Bar data={profitChartData} options={options} />
    </div>
  )
}

export default ProfitChart
