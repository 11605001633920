import { z } from 'zod'

import { LaborType, RoleType, SalaryCadence, SalaryType } from './enums'

const CreateProjectSchema = z.object({
  projectName: z.string().min(8, 'Project name is required and must be at least 8 characters'),
  projectDescription: z.string().min(8, 'Project description is required and must be at least 8 characters')
})

type CreateProjectType = z.infer<typeof CreateProjectSchema>

const ResourcePlanFeedbackSchema = z.object({
  strategicGoalsAlignment: z.enum(
    ['completely-agree', 'somewhat-agree', 'neutral', 'somewhat-disagree', 'completely-disagree'],
    { errorMap: () => ({ message: 'Required' }) }
  ),
  feedback: z.string().min(50, 'Additional feedback is required and must be at least 50 characters')
})

type ResourcePlanFeedbackType = z.infer<typeof ResourcePlanFeedbackSchema>

const RegisterUserSchema = z
  .object({
    email: z.string().email('Required'),
    name: z.string().min(5, 'Required'),
    password: z
      .string()
      .min(8, 'Password is required and must be at least 8 characters')
      .regex(/[0-9]/, 'Password must contain at least 1 number')
      .regex(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least 1 special character')
      .regex(/[A-Z]/, 'Password must contain at least 1 uppercase letter')
      .regex(/[a-z]/, 'Password must contain at least 1 lowercase letter'),
    confirmPassword: z.string().min(8, 'Password is required and must be at least 8 characters')
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword']
  })

type RegisterUserType = z.infer<typeof RegisterUserSchema>

const VerifyUserSchema = z.object({
  email: z.string().email('Required'),
  confirmationCode: z.string().min(6, 'Valid confirmation code required').max(6, 'Valid confirmation code required')
})

type VerifyUserType = z.infer<typeof VerifyUserSchema>

const CompanyProfileSchema = z.object({
  tenantName: z.string(),
  tenantIndustry: z.string(),
  tenantLocation: z.string(),
  tenantDescription: z.string()
})

type CompanyProfileType = z.infer<typeof CompanyProfileSchema>

const LoginSchema = z.object({
  email: z.string().email('Required'),
  password: z.string().min(8, 'Required')
})

type LoginType = z.infer<typeof LoginSchema>

const InviteUserSchema = z.object({
  email: z.string().email('Required'),
  name: z.string().min(8, 'Required')
})

type InviteUserType = z.infer<typeof InviteUserSchema>

const ChangeTemporaryPasswordSchema = z
  .object({
    password: z
      .string()
      .min(8, 'Password is required and must be at least 8 characters')
      .regex(/[0-9]/, 'Password must contain at least 1 number')
      .regex(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least 1 special character')
      .regex(/[A-Z]/, 'Password must contain at least 1 uppercase letter')
      .regex(/[a-z]/, 'Password must contain at least 1 lowercase letter'),
    confirmPassword: z.string().min(8, 'Password is required and must be at least 8 characters')
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword']
  })

type ChangeTemporaryPasswordType = z.infer<typeof ChangeTemporaryPasswordSchema>

const ForgotPasswordSchema = z.object({
  email: z.string().email('Required')
})

type ForgotPasswordType = z.infer<typeof ForgotPasswordSchema>

const ConfirmResetPasswordSchema = z
  .object({
    newPassword: z
      .string()
      .min(8, 'Password is required and must be at least 8 characters')
      .regex(/[0-9]/, 'Password must contain at least 1 number')
      .regex(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least 1 special character')
      .regex(/[A-Z]/, 'Password must contain at least 1 uppercase letter')
      .regex(/[a-z]/, 'Password must contain at least 1 lowercase letter'),
    confirmPassword: z.string().min(8, 'Password is required and must be at least 8 characters'),
    confirmationCode: z.string().min(6, 'Valid confirmation code required').max(6, 'Valid confirmation code required')
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword']
  })

type ConfirmResetPasswordType = z.infer<typeof ConfirmResetPasswordSchema>

const AddPersonnelSchema = z.object({
  name: z.string().min(2, 'Required'),
  role: z.string().min(2, 'Required'),
  laborType: z.nativeEnum(LaborType, { errorMap: () => ({ message: 'Required' }) }),
  roleType: z.nativeEnum(RoleType, { errorMap: () => ({ message: 'Required' }) }),
  salaryType: z.nativeEnum(SalaryType, { errorMap: () => ({ message: 'Required' }) }),
  salary: z.string().min(1, 'Required'),
  salaryCadence: z.nativeEnum(SalaryCadence, { errorMap: () => ({ message: 'Required' }) }),
  startDate: z.string().date()
})

type AddPersonnelType = z.infer<typeof AddPersonnelSchema>

export {
  CreateProjectSchema,
  type CreateProjectType,
  ResourcePlanFeedbackSchema,
  type ResourcePlanFeedbackType,
  RegisterUserSchema,
  type RegisterUserType,
  VerifyUserSchema,
  type VerifyUserType,
  CompanyProfileSchema,
  type CompanyProfileType,
  LoginSchema,
  type LoginType,
  InviteUserSchema,
  type InviteUserType,
  ChangeTemporaryPasswordSchema,
  type ChangeTemporaryPasswordType,
  ForgotPasswordSchema,
  type ForgotPasswordType,
  ConfirmResetPasswordSchema,
  type ConfirmResetPasswordType,
  AddPersonnelSchema,
  type AddPersonnelType
}
