import { CSSProperties, ReactNode } from 'react'

import { ClipLoader } from '@/components'

const Card = ({
  title,
  footer,
  loading = false,
  padding = true,
  cardStyles,
  children
}: {
  title?: string | ReactNode
  footer?: ReactNode
  padding?: boolean
  loading?: boolean
  cardStyles?: CSSProperties
  children: ReactNode
}) => {
  return (
    <div style={cardStyles} className="divide-y divide-gray-200 rounded-lg bg-white border border-smet-secondary-text">
      {title && <div className="px-4 py-5 sm:px-6 font-semibold">{title}</div>}
      {loading ? (
        <div className="flex h-60 items-center justify-center">
          <ClipLoader />
        </div>
      ) : (
        <>
          <div className={`${padding ? 'px-4 py-5 sm:p-6' : ''}`}>{children}</div>
          {footer && <div className="px-4 py-4 sm:px-6">{footer}</div>}
        </>
      )}
    </div>
  )
}

export default Card
