import { Card, ProgressBar } from '@/components'
import { Text } from '@/components/catalyst'

const DecisionTreeCard = ({ decisions }: { decisions: Decision[] }) => {
  return (
    <Card title="Decision Tree">
      {decisions.length > 0 ? (
        <ProgressBar steps={decisions} />
      ) : (
        <Text>Decisions you make based on your interaction with your Assistant will appear here.</Text>
      )}
    </Card>
  )
}

export default DecisionTreeCard
