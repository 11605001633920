import { Table, TableBody, TableCell, TableRow } from '@/components/catalyst'
import { formatPercent } from '@/components/salary-calculator/lib/numbro'

import { resourceType } from './schemas'
import { TABLE_CONFIG } from './config'

const ViewSelfGuidedResourcePlanTable = ({ resourcePlan }: { resourcePlan: resourceType[] }) => {
  const totals = {
    totalBillRate: 0,
    totalFullyBurdenedRate: 0,
    totalRevenue: 0,
    totalHourlyProfit: 0,
    totalProfit: 0
  }

  return (
    <>
      <Table grid dense>
        <TableBody>
          {TABLE_CONFIG.map((config) => {
            const { label, key, aggregateKey, compute, format } = config

            return (
              <TableRow key={label}>
                <TableCell className="font-bold">{label}</TableCell>
                {resourcePlan.map((resource, index) => {
                  if (aggregateKey) {
                    totals[aggregateKey as keyof typeof totals] += compute
                      ? compute(resource)
                      : Number(resource[key as keyof typeof resource])
                  }

                  return (
                    <TableCell key={`resource-${index}`}>
                      {format
                        ? format(compute ? compute(resource) : Number(resource[key as keyof typeof resource]))
                        : resource[key as keyof typeof resource]}
                    </TableCell>
                  )
                })}
                <TableCell className="font-bold">
                  {aggregateKey ? format(totals[aggregateKey as keyof typeof totals]) : ''}
                </TableCell>
              </TableRow>
            )
          })}
          <TableRow>
            <TableCell className="font-bold">Profit Margin</TableCell>
            {resourcePlan.map((resource, index) => {
              return <TableCell key={`resource-${index}`} />
            })}
            <TableCell className="font-bold">{formatPercent(totals.totalProfit / totals.totalRevenue || 0)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}

export default ViewSelfGuidedResourcePlanTable
