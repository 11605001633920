import { ReactNode, useEffect, useState } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { signOut } from 'aws-amplify/auth'

import {
  HomeIcon,
  BuildingOfficeIcon,
  BriefcaseIcon,
  RectangleGroupIcon,
  UserGroupIcon,
  UserPlusIcon,
  CalculatorIcon
} from '@heroicons/react/16/solid'

import { ClipLoader, Dialog, SalaryCalculator, usePersonnel, useUser } from '@/components'

import SidebarNav from './SidebarNav'

const dropdownItems = [
  {
    label: 'Company Profile',
    href: '/company-profile',
    icon: <BuildingOfficeIcon />
  },
  {
    label: 'Invite User',
    href: '/invite-user',
    icon: <UserPlusIcon />
  }
]

const CompanyNav = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname()
  const router = useRouter()
  const { user, setUser, setCompanyProfile, loading } = useUser()
  const { setPersonnel } = usePersonnel()

  const [loadingRecentProjects, setLoadingRecentProjects] = useState<boolean>(false)
  const [recentProjects, setRecentProjects] = useState<Project[] | null>()
  const [showCalculatorModal, setShowCalculatorModal] = useState<boolean>(false)

  const items = [
    {
      label: 'Home',
      href: '/dashboard',
      icon: <HomeIcon />
    },
    {
      label: 'Projects',
      href: '/projects',
      icon: <RectangleGroupIcon />
    },
    {
      label: 'Create Opportunity',
      href: '/create-opportunity',
      icon: <BriefcaseIcon />
    },
    {
      label: 'Team',
      href: '/team',
      icon: <UserGroupIcon />
    },
    {
      label: 'Salary Calculator',
      onClick: () => setShowCalculatorModal(true),
      icon: <CalculatorIcon />
    }
  ]

  const logout = async () => {
    try {
      await signOut()
      sessionStorage.clear()
      setUser(null)
      setCompanyProfile(null)
      setPersonnel([])
      router.push('/auth/login')
    } catch (error) {
      alert(error)
    }
  }

  useEffect(() => {
    const getRecentProjects = async () => {
      try {
        const res = await fetch('/api/projects/recent', {
          method: 'GET'
        })

        if (res.ok) {
          res.json().then((json) => {
            setRecentProjects(json.data.projects)
          })
        }
      } catch (error) {
        setRecentProjects(null)
      } finally {
        setLoadingRecentProjects(false)
      }
    }

    getRecentProjects()
  }, [])

  if (loading || loadingRecentProjects) {
    return (
      <div className="flex h-screen items-center justify-center">
        <ClipLoader />
      </div>
    )
  }

  const additionalItems = recentProjects?.map((project) => {
    return {
      label: project.project_name,
      href: `/projects/${project.id}`
    }
  })

  return (
    <>
      <SidebarNav
        userName={user?.attributes.name}
        userEmail={user?.attributes.email}
        currentPath={pathname}
        sidebarItems={items}
        additionalItems={additionalItems && { heading: 'Recent Projects', items: additionalItems }}
        dropdownItems={dropdownItems}
        logout={logout}
      >
        {children}
      </SidebarNav>
      {showCalculatorModal && (
        <Dialog open size="5xl" onClose={() => setShowCalculatorModal(false)}>
          <SalaryCalculator />
        </Dialog>
      )}
    </>
  )
}

export default CompanyNav
