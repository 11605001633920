import { Amplify } from 'aws-amplify'

import { NextServer, createServerRunner } from '@aws-amplify/adapter-nextjs'
import { fetchAuthSession, fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth/server'

let isAmplifyConfigured = false

export const config = {
  Auth: {
    Cognito: {
      userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID || '',
      identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID || '',
      userPoolClientId: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID || ''
    }
  }
}

export const configureAmplify = () => {
  if (!isAmplifyConfigured) {
    Amplify.configure(config)
    isAmplifyConfigured = true
  }
}

export const { runWithAmplifyServerContext } = createServerRunner({ config })

export const authenticatedUser = async (context: NextServer.Context) => {
  return await runWithAmplifyServerContext({
    nextServerContext: context,
    operation: async (contextSpec: any) => {
      try {
        const session = await fetchAuthSession(contextSpec)

        if (!session.tokens) {
          return
        }

        const user = {
          ...(await getCurrentUser(contextSpec)),
          attributes: {
            ...(await fetchUserAttributes(contextSpec))
          }
        }

        return user
      } catch (error) {
        return
      }
    }
  })
}
