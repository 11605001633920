import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'

import { Card, CreateSelfGuidedResourcePlanTable } from '@/components'
import { Button, Field, Input } from '@/components/catalyst'

import { resourcePlanType, ResourcePlanSchema } from './schemas'

const CreateSelfGuidedResourcePlanCard = ({
  insights = false,
  resourcePlan,
  handleDone,
  handleChange
}: {
  insights?: boolean
  resourcePlan?: resourcePlanType
  handleDone: (resourcePlan: resourcePlanType) => void
  handleChange?: (resourcePlan: resourcePlanType) => void
}) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors }
  } = useForm<resourcePlanType>({
    defaultValues: resourcePlan,
    resolver: zodResolver(ResourcePlanSchema),
    mode: 'onChange'
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'resourcePlan'
  })

  const handleAppendRow = () => {
    const { resourcePlan } = getValues()
    append(resourcePlan[resourcePlan.length - 1])
  }

  const values = watch()

  useEffect(() => {
    if (handleChange) {
      handleChange(values)
    }
  }, [JSON.stringify(values), handleChange])

  return (
    <Card
      title={
        <div className="space-y-2 sm:flex sm:justify-between sm:space-y-0">
          <div>
            <Field>
              <Input {...register('name')} invalid={!!errors?.name} />
            </Field>
          </div>
          <div className="flex justify-between items-center space-x-2">
            <Field>
              <Input {...register('startDate')} type="date" invalid={!!errors?.startDate} />
            </Field>
            <span>to</span>
            <Field>
              <Input {...register('endDate')} type="date" invalid={!!errors?.endDate} />
            </Field>
          </div>
        </div>
      }
      footer={
        <div className="flex justify-between">
          <div>
            {insights && (
              <Button plain className="cursor-pointer">
                AI Insights
              </Button>
            )}
          </div>
          <Button
            className="smet-button"
            onClick={handleSubmit((resourcePlan: resourcePlanType) => handleDone(resourcePlan))}
          >
            Done
          </Button>
        </div>
      }
    >
      <CreateSelfGuidedResourcePlanTable
        fields={fields}
        values={values.resourcePlan}
        errors={errors}
        register={register}
        append={handleAppendRow}
        remove={remove}
      />
    </Card>
  )
}

export default CreateSelfGuidedResourcePlanCard
