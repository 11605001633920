import { useState } from 'react'

import { SparklesIcon } from '@heroicons/react/16/solid'
import { PencilIcon } from '@heroicons/react/24/solid'

import { Card, Notification } from '@/components'
import { Button } from '@/components/catalyst'

import SaveResourcePlanModal from '../SaveResourcePlanModal'

import ViewSelfGuidedResourcePlanTable from './ViewSelfGuidedResourcePlanTable'
import { resourcePlanType } from './schemas'

const ViewSelfGuidedResourcePlanCard = ({
  resourcePlan,
  projectId,
  handleEdit,
  handleProjectCreation,
  handleAiInsights
}: {
  resourcePlan?: resourcePlanType
  projectId: string
  handleEdit: () => void
  handleProjectCreation: (projectId: string) => void
  handleAiInsights: () => void
}) => {
  const [isCreatingProject, setIsCreatingProject] = useState<boolean>(false)
  const [showNotification, setShowNotification] = useState<boolean>(false)

  const saveResourcePlan = async (projectId: string) => {
    try {
      const res = await fetch(`api/projects/${projectId}/resource-plans`, {
        method: 'POST',
        body: JSON.stringify({
          resourcePlan: resourcePlan?.resourcePlan,
          name: resourcePlan?.name,
          selfGuided: true,
          totals: null,
          startDate: resourcePlan?.startDate,
          endDate: resourcePlan?.endDate
        })
      })

      if (res.ok) {
        setShowNotification(true)
      } else {
        const error = await res.json().then((json) => json.error)
        throw new Error(error)
      }
    } catch (error) {
      alert(error)
    }
  }

  const handleSaveResourcePlan = (projectId: string) => {
    if (projectId) {
      saveResourcePlan(projectId)
    } else {
      setIsCreatingProject(true)
    }
  }

  const handleResourcePlanModalClose = (projectId?: string) => {
    if (projectId) {
      handleProjectCreation(projectId)
      handleSaveResourcePlan(projectId)
    }

    setIsCreatingProject(false)
  }

  return (
    <>
      <Card
        title={
          <div className="flex justify-between">
            <div>{resourcePlan?.name || 'Resource Plan'}</div>
            {resourcePlan?.startDate && resourcePlan?.endDate && (
              <div className="font-normal">
                {new Date(resourcePlan.startDate).toLocaleDateString('en-US', { timeZone: 'UTC' })} to{' '}
                {new Date(resourcePlan.endDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}
              </div>
            )}
          </div>
        }
        footer={
          <div className="flex items-center justify-between">
            <div>
              <Button outline className="cursor-pointer" onClick={handleAiInsights}>
                AI Insights
                <SparklesIcon />
              </Button>
            </div>
            <div className="flex space-x-2">
              <PencilIcon className="h-5 w-5 text-smet-secondary-text cursor-pointer" onClick={handleEdit} />
              <div
                className="h-5 w-5 text-smet-secondary-text cursor-pointer"
                onClick={() => handleSaveResourcePlan(projectId)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M18 2.25c.2 0 .39.08.53.22l3 3c.14.14.22.33.22.53v15c0 .41-.34.75-.75.75H3a.75.75 0 0 1-.75-.75V3c0-.41.34-.75.75-.75h15ZM17 12H7a1 1 0 0 0-1 1v7.25h12V13a1 1 0 0 0-1-1Zm-.5-8.25h-9V8a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V3.75Zm-2.25.75c.41 0 .75.34.75.75v1.5a.75.75 0 1 1-1.5 0v-1.5c0-.41.34-.75.75-.75Z" />
                </svg>
              </div>
            </div>
          </div>
        }
      >
        <ViewSelfGuidedResourcePlanTable resourcePlan={resourcePlan?.resourcePlan || []} />
      </Card>
      {isCreatingProject && (
        <SaveResourcePlanModal
          projectName=""
          handleClose={(projectId?: string) => handleResourcePlanModalClose(projectId)}
        />
      )}
      {showNotification && (
        <Notification
          title="Success!"
          text="Resource plan was successfully created."
          handleClose={() => setShowNotification(false)}
        />
      )}
    </>
  )
}

export default ViewSelfGuidedResourcePlanCard
