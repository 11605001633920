'use client'

import { Amplify } from 'aws-amplify'

import { config } from '@/utils/auth'

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  Amplify.configure(config, { ssr: true })

  return children
}

export default AuthProvider
