import Image from 'next/image'

import Logo from '@assets/images/logos/be-logo.svg'
import { Heading } from '@/components/catalyst'

const AuthContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="hidden relative lg:block">
        <Heading className="text-smet-primary-blue !text-4xl w-80 absolute bottom-20 -left-20">
          Swift decisions grow businesses
        </Heading>
        <Image src="/assets/images/be-1.png" alt="" priority width={459} height={504} />
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="mb-8">
          <Image src={Logo} alt="BDecisive Logo" priority />
        </div>
        <div className="bg-smet-blue-light-4 p-9 rounded-lg shadow-md w-96 space-y-8">{children}</div>
      </div>
    </div>
  )
}

export default AuthContainer
